<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          outlined
          class="col-2"
          dense
          v-model="FilterString"
          label="分类名"
          color="primary"
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmMaTypeListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
    
        <q-btn color="primary" style="height: 38px" icon="note_add" :loading="PdmMaTypeListLoading"
          @click="onAdd">新增</q-btn>
      </div>
      <vxe-grid v-bind="gridOptions" :data="PdmMaTypeList" :sort-config="{ multiple: true }" :custom-config="{ storage: true }"
        id="custom-config" :export-config="gridOptions.tableExport" :height="$store.getters.maxPageHeight - 140"
        :loading="PdmMaTypeListLoading" highlight-current-row size="mini">
        <template #IsValid="{ row }">
          {{ row.IsValid == 0 ? '否' : '是' }}
        </template>
        <template #operate="{ row }">
          <vxe-button flat color="primary" icon="fas  fa-edit" @click="onEdit(row)">编辑</vxe-button>
          <!-- <vxe-button flat color="negative" icon="fas fa-trash" @click="onDelete(row)">删除</vxe-button> -->
          <vxe-button flat :status="row.IsValid == 0 ? 'danger' : 'primary'" color="negative" icon="fas fa-trash"
            @click="onIsValid(row)">{{ row.IsValid == 0 ? "禁用" : "启用" }}</vxe-button>
        </template>
      </vxe-grid>
      <vxe-pager perfect size="mini" :page-sizes="tablePage.pageSizes" v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize" :total="tablePage.totalResult" :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]" @page-change="handlePageChange">
      </vxe-pager>
    </q-card-section>
  </q-card>

  <q-dialog v-model="pdmSmsshowDlg" persistent>
    <TypeEdit />
  </q-dialog>
</template>
  
<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import TypeEdit from "./typeEdit.vue";
export default {
  components: {
    TypeEdit,
  },
  computed: {
    ...mapState("PdmMaType", [
      "PdmMaTypeList",
      "PdmMaTypeListLoading",
      "PdmMaTypeEntity",
      "PdmMaTypeShowDlg",
    ]),
    ...mapState("SysUser", [
      "UserList"
    ]),

    pdmSmsshowDlg: {
      get() {
        return this.PdmMaTypeShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaType/setPdmMaTypeShowDlg", val);
      },
    },
  },
  data() {
    return {
      FilterString: "",
      HospID: "0",
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      userListData:[],
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
          },
        },

        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "TypeName",
            title: "分类名称",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "TypeSort",
            title: "排序",
            sortable: true,
            align: "left",
            width: 50,
          },
          {
            slots: { default: "IsValid" },
            title: "是否禁用",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            title: "操作",
            width: 200,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },

        ],
        // data: this.PdmMaTypeList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmMaType", [
      "actGetPdmMaTypeListByPage",
      "actCreatePdmMaType",
      "actEditPdmMaTypeData",
      "actEditPdmMaType",
      "actDeletePdmMaType",
      "actUpdatePdmMaType",
      "actSetPDM_MA_TypeIsValid"
    ]),
    ...mapActions("SysUser", [
      "actLoadData"
    ]),
    onLoadData() {
      this.actGetPdmMaTypeListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          // this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit("PdmMaType/setPdmMaTypeListLoading", false);
          //this.PdmMaTypeListLoading = false;
        });

      this.actLoadData({})
        .then((res) => {
          if (res.Code == 0) this.userListData = res.Data;
        })
        .catch((res) => {
          this.msg.error(res.Message || res);
        });
    },
    onAdd() {
      this.actCreatePdmMaType({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      });
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmMaTypeData(obj);
    },
    onIsValid(e){
      var obj = {};
      Object.assign(obj, e);
      this.actSetPDM_MA_TypeIsValid({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: obj,
      }).then((res) => {
        if (res.Code == 0) {
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onDelete(e) {
      console.log(`output->`,e.TypeID)
      this.actDeletePdmMaType({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        TypeID: e.TypeID,
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
  